import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import SaasProviders from "./_saasProviders";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title="Automated Backup for your SaaS services"
      description="Automated Backup for your SaaS applications and services. Schedule your backups and store them on your preferred cloud storage provider."
    />
    <Layout.Hero
      type="primary"
      title={(<>Your <span className="text-primary">SaaS apps</span><br/> also deserve<br/> a backup!</>)}
      subTitle={(
        <>Secure your most precious data by automating the backup of your SaaS app to your preferred storage. Don't rely on your SaaS provider to do it for you.</>)}
      checkMarks={['Bring your own storage', 'Security & Encryption']}
      icons={[
        <Ui.Image filename="brands/icons/github-ico.png" alt="GitHub backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/knack-ico.png" alt="Knack backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/gitea-ico.png" alt="Gitea backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Schedule your SaaS App backup" urlAtrr={{ sb_source: "website", sb_term: "saas-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-hero-dashboard.png" alt="SimpleBackups PostgreSQL backup dashboard: all your PostgreSQL backups in one place"/>
      )}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                title: (<>Built by <span className="text-primary">experts</span>, so you don’t have to.</>),
                description: (
                  <>
                    <p>
                      Protecting your critical SaaS data shouldn't be a headache.<br/>
                      Enjoy seamless integrations with popular SaaS platforms, robust monitoring systems, and user-friendly tools designed to make application backups effortless.
                      <br/>
                      <Ui.FeatureChecks items={['Custom Schedule', 'Advanced notifications', 'Team access', 'Failover detection', 'Centralized Backup Management', 'End-to-end backup strategy']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-mysql-database.png'
                  alt="SaaS App backup"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
              Content_FeatureControl({subject: 'SaaS'}),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Supported SaaS Apps</Ui.Typography>
          <p className="mb-6 text-center">Effortlessly connect your SaaS account with your SimpleBackups. Select what you want to backup, your destination storage and start protecting your data.</p>
          <div className="mt-16 text-center">
            <SaasProviders></SaasProviders>
            <div className="mt-16"><Ui.Link arrow="right" to="#features">Discover our Integration catalog</Ui.Link></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_saas_app_backup_service', 'what_github_backup_service', 'use_for_client_2', 'use_for_other_things']}/>


  </Layout.Layout>
)

export default ContentPage
