import React from "react";
import Image from "components/image";
import providers from '../../configs/saas-providers.js';
import { Link } from "gatsby";

const BucketProviders = () => {
  return (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-5 text-left md:max-w-4xl m-auto">
        {providers.map(provider => (
          <Link to={`/saas-backup/${provider.key}`} className="flex justify-between flex-col bg-white border-indigo-100 border rounded-3xl py-8 px-4 text-sm text-center">
            <div className="flex items-center relative justify-center mb-3">
              <Image filename={provider.image} alt={`${provider} backup`} className="w-12" />
            </div>
            <div>
              <p className="font-bold mb-1">{provider.name}</p>
              <p className="opacity-75">{provider.text}</p>
            </div>
          </Link>
        ))}
      </div>
  )
}

export default BucketProviders;
